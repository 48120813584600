
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import CertificateEditModal from '@/layout/header/partials/trainee/certificateDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
import QrcodeVue from 'qrcode.vue';

export default defineComponent({
    mixins: [apiEndpoint],
    name: 'training-certificate-configuration',
    components: {
        Form,
        Field,
        Datatable,
        QrcodeVue,
        CertificateEditModal
    },
    data() {
        return {
           batch: {
                entity_id: '',
                tranche_id: '',
                institute_id: '',
                course_id: '',
            },
            rightInfos: [] as any,
              is_seip_user: false,
            is_entity_user: false,
            is_institute_user: false,
            api_url: '',
            AssetSection: false,
            fileone: '' as any,
            filetwo: '' as any,
            left: '' as any,
            middle: '' as any,
            right: '' as any,
            left_signature: '' as any,
            right_signature: '' as any,
            entityInfos: {
                entity_name: '',
                entity_short_name: '',
                image_file_name: '',
            } as any,
            BackgroundInfos: {
                left_signatory: '',
                middle_signatory: '',
                right_signatory: '',

            } as any,
            Infos: {
                id: '',
                entity_id: '',
                left_signatory: '',
                middle_signatory: '',
                right_signatory: '',
                active_status: '',
                background_image: ''
            } as any,

            tranche: [] as any,
             type: '' as any,
            tranches: [] as any,
            association: [] as any,
            btnCheck: false,
            instituteCheck: false,
            actionActive: false,
            actionDelete: false,
            background_image: "" as any,
            instituteList: [],
            courseList: [],
            actionEdit: false,
            checkedData: [],
            traineeList: [] as any,
            batchList: [],
            tranches_info: [],
            tableHeader: [
                {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                },
                {
                    name: 'SL',
                    key: 'sl',
                    sortable: true,
                },
                {
                    name: 'Left Signature',
                    key: 'photo',
                    sortable: true,
                },
                 {
                    name: 'Left Signatory',
                    key: 'left',
                    sortable: false,
                },
                {
                    name: 'Right Signature',
                    key: 'right-photo',
                    sortable: true,
                },
                {
                    name: 'Right Signatory',
                    key: 'right',
                    sortable: false,
                },
                {
                    name: 'Status',
                    key: 'status',
                    sortable: false,
                },

               


            ],

             tableHeaderentity: [
                {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                },
                {
                    name: 'SL',
                    key: 'sl',
                    sortable: true,
                },
                {
                    name: 'Right Signature',
                    key: 'right-photo',
                    sortable: true,
                },
                {
                    name: 'Right Signatory',
                    key: 'right',
                    sortable: false,
                },
                {
                    name: 'Status',
                    key: 'status',
                    sortable: false,
                },




            ],
            showFormEntity: false,
            showFormTI: false,
            showDataTable: false,
            showTable: false,
            componentKey: 0,
            loading: false,
            load: false,
            qrData: '' as any,
            courseInfoData: false,
            showCourseNotice: false,
            certificationdate: '' as any,

        };

    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
         if (VueCookieNext.getCookie("_seip_entity_type") == 1004 || VueCookieNext.getCookie("_seip_entity_type") == 1000) {
            this.is_seip_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") != 1004 && VueCookieNext.getCookie("_seip_entity_type") != 1011) {
            this.is_entity_user = true;
            this.is_institute_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
            this.is_institute_user = true;
            // this.type = 'Training Institute';
            this.batch.institute_id= VueCookieNext.getCookie("_institute_info_id")
        }
        await this.getAssociation();
        await this.getEntityInfos();
        this.emitter.on('certificate-updated', async () => {
            this.allTemplate();
            
        });
    },
    methods: {

        onLeftChange(event) {
            console.log();
            let filename = event.target.files[0]?.name;
            console.log(filename);
            this.fileone = [];
            this.fileone = event.target.files ? event.target.files[0] : null;

        },

        onRightChange(event) {
            console.log();
            let filename = event.target.files[0]?.name;
            console.log(filename);
            this.filetwo = [];
            this.filetwo = event.target.files ? event.target.files[0] : null;

        },
         async getAssociation() {
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get('entity/list?entity_id=' + entity_id)
                .then((response) => {
                    this.association = response.data.data;
                    console.log(response);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
         async getTrainingInstitute() {
            this.loading = true;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            await ApiService.get(
                this.VUE_APP_INSTITUTE_LIST_API +
                '?entity_id=' +
                this.batch.entity_id +
                '&institute_info_id=' +
                institute_info_id
            )
                .then((response) => {
                    this.instituteList = response.data.data;
                    this.loading = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                    this.loading = false;
                });
        },
        async formSubmit() {

            let formdata = new FormData();
            if (this.batch.entity_id != '') {
                formdata.set('entity_id', this.batch.entity_id);
                formdata.set('institute_id', this.batch.institute_id);
                formdata.set('image2', this.filetwo);
                formdata.set('image1', this.fileone);
                formdata.set('left', this.left);
                formdata.set('right', this.right);
                formdata.set('type', this.type);
                ApiService.post(this.VUE_APP_CERTIFICATION_CONFIG_API, formdata)
                    .then((response) => {
                        this.load = false;

                        if (response?.status == 200) {
                            if (response.data.status == 'success') {
                                Swal.fire({
                                    title: 'Saved Successfully!',
                                    text: response.data.data,
                                    icon: 'success',
                                    buttonsStyling: false,
                                    confirmButtonText: 'Preview Certificate',
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                    },
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.preview();
                                        this.BackgroundImage();
                                    }
                                });
                            }
                            if (response.data.status == 'error') {
                                Swal.fire({
                                    title: 'Error!',
                                    text: response.data.message,
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: 'Close',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                });
                            }
                        } else {
                            let err = '';
                            for (const field of Object.keys(response.data.errors)) {
                                err += response.data.errors[field][0] + '<br>';
                            }
                            Swal.fire({
                                title: response.data.errors,
                                html: err,
                                icon: 'error',
                                buttonsStyling: false,
                                confirmButtonText: 'Close',
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                            });
                        }
                    })
                    .catch((response) => {
                        this.load = false;
                        console.log(response);
                    });
            }
            else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please Select Association!',
                    icon: 'warning',
                    buttonsStyling: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                this.load = false;
                this.loading = false;
            }

        },
        async preview() {
            this.loading = true;
            let formdata = new FormData();
            formdata.set('entity_id', this.batch.entity_id);
            await ApiService.get("assessment/certificate/getlogo?entity=" + this.batch.entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.entityInfos = response.data.data;

                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
        },

        Inactive(id) {
            let user_id = VueCookieNext.getCookie('_seip_user');
            Swal.fire({
                title: 'Are you sure you want to inactive it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Inactive!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.get(
                        'assessment/certificate/status-change?id=' +
                        id +
                        '&active_status=0' +
                        '&responsible_user_id=' +
                        user_id.id + '&entity_id=' + this.batch.entity_id + '&institute_id=' + this.batch.institute_id
                    )
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Inactivated!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);
                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },
        Active(id) {
            let user_id = VueCookieNext.getCookie('_seip_user');
            Swal.fire({
                title: 'Are you sure you want to Activate it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Activate!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.get(
                        'assessment/certificate/status-change?id=' +
                        id +
                        '&active_status=1' +
                        '&responsible_user_id=' +
                        user_id.id + '&entity_id=' + this.batch.entity_id + '&institute_id=' + this.batch.institute_id
                    )
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Activated!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);

                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },
        async allForm() {
            if (this.type == 'Training Partner') {
                this.showFormEntity = true;
                this.showFormTI = false;
                this.showDataTable = false;
                this.showTable = false;
            }
             if (this.type == 'Training Institute') {
                 this.showFormTI = true;
                 this.showFormEntity = false;
                 this.showDataTable = false;
                this.showTable = false;
            }  
          },
         async btnCheckStatus() {
            this.load = true;
             this.batch.entity_id = '';
             this.batch.institute_id = '';
            if (this.type) {
                if (this.type !== 'SEIP') {
                    this.btnCheck = true;
                }
                else {
                    this.btnCheck = false;
                    this.instituteCheck = false;
                }
                if (this.type == 'Training Institute') {
                    this.instituteCheck = true;
                }
                else {
                    this.instituteCheck = false;
                }

                this.load = false;
            } else {
                Swal.fire({
                    title: 'Organization Type Need to be Select!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete!',
                });
            }
            // else popup
        },

        async view(id) {
            this.loading = true;
            await ApiService.get("assessment/certificate/single?id=" + id + '&institute_id=' + this.batch.institute_id + '&entity=' + this.batch.entity_id
            )
                .then((response) => {
                    this.loading = false;
                    console.log(response.data.data);
                    this.BackgroundInfos = response.data.data;
                    this.preview();
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
            this.showDataTable = true;
            this.showTable = false;

        },

        Delete(id) {
            Swal.fire({
                title: 'Are you sure you want to delete it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete('assessment/certificate/delete?id=' + `${id}`)
                        .then((response) => {
                            if (response.data.status == 'error') {
                                this.load = false;
                                Swal.fire({
                                    title: 'Error!',
                                    html: response.data.message,
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: 'Close',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                });
                            } else {
                                Swal.fire('Deleted!', response.data.data, 'success').then(
                                    () => {
                                        this.showTable = true;
                                        this.emitter.emit('certificate-updated', true);


                                    }
                                );
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },

        edit(data) {
            this.emitter.emit('certificate-edit', data);
        },

        async BackgroundImage() {
            this.loading = true;
            let formdata = new FormData();
            formdata.set('entity_id', this.batch.entity_id);
            await ApiService.get("assessment/certificate/background?entity=" + this.batch.entity_id + '&institute_id=' + this.batch.institute_id
            )
                .then((response) => {
                    this.loading = false;
                    this.BackgroundInfos = response.data.data;
                   this.qrData = `${this.VUE_APP_API_URL}/certificate/training-certificate-view`;
                    this.showTable = false;
                    this.showDataTable = true;

                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });

        },

        async allTemplate() {
            if (this.batch.entity_id != '') {
                this.loading = true;
                let formdata = new FormData();
                formdata.set('entity_id', this.batch.entity_id);
                await ApiService.get("assessment/certificate/allbackground?entity=" + this.batch.entity_id + '&institute_id=' + this.batch.institute_id
                )
                    .then((response) => {
                        this.loading = false;
                        if(this.type == 'Training Institute') {
                            this.Infos = response.data.data.left; 
                        }
                        else {
                           this.Infos = response.data.data.right;
                        }
                         this.rightInfos = response.data.data.right; 
                        this.showDataTable = false;
                        this.showTable = true;
                         this.showFormEntity = false;
                        this.showFormTI = false;

                        this.componentKey += 1;
                    })
                    .catch(({ response }) => {
                        this.loading = false;
                        console.log(response);
                    });

            }
            else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please Select Association!',
                    icon: 'warning',
                    buttonsStyling: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });

            }
        },

        async getEntityInfos() {
            this.loading = true;
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get(
                this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.entityInfos = response.data.data;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
        },
    },
    setup() {
        const AssociationSchema = Yup.object().shape({
            //entity_name: Yup.string().required().label("Name"),
            //entity_short_name: Yup.string().required().label("Short Name"),
        });
        return {
            AssociationSchema,
        };
    },
});
